import './App.css';
import {useEffect } from 'react';
import menu from './Images/menu.png';
import profile from './Images/profile.jpg';
const resumeUrl = '/documents/resume.pdf';

function App() {
  useEffect(() => {
    const button = document.querySelector('#menuButton');
    const sidebar = document.querySelector('.sidebar');

    function openSidebar() {
      sidebar.classList.toggle('active');
    }

    button.addEventListener('click', openSidebar, false);

    // Clean up the event listener when the component unmounts
    return () => {
      button.removeEventListener('click', openSidebar, false);
    };
  });

  return (
    <div className="container">
      <div className="sidebar">
        <div className="title">
          <img src={menu} id="menuButton" alt="menu button"/>
        </div>
        <div className="profile">
          <img src={profile} alt="profile picture" className="profilePic"/>
          <div className="profileDetails">
            <p className="sidebarInfo">
              <span>Darren Tan Thong En</span>
            </p>
            <p className="sidebarInfo">
              <span>Curtin University Malaysia</span>
            </p>
            <p className="sidebarInfo">
              <span>Software Engineer</span>
            </p>
          </div>
        </div>
        <ul className="sidebar navbar">
          <li>
            <a href="#home">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z"/></svg>
              <span className="text">Home</span>
            </a>
          </li>
          <li>
            <a href="#about">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
              <span className="text">About</span>
            </a>
          </li>
          <li>
            <a href="#skills">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M160-200q-33 0-56.5-23.5T80-280v-400q0-33 23.5-56.5T160-760h640q33 0 56.5 23.5T880-680v400q0 33-23.5 56.5T800-200H160Zm0-80h640v-400H160v400Zm160-40h320v-80H320v80ZM200-440h80v-80h-80v80Zm120 0h80v-80h-80v80Zm120 0h80v-80h-80v80Zm120 0h80v-80h-80v80Zm120 0h80v-80h-80v80ZM200-560h80v-80h-80v80Zm120 0h80v-80h-80v80Zm120 0h80v-80h-80v80Zm120 0h80v-80h-80v80Zm120 0h80v-80h-80v80ZM160-280v-400 400Z"/></svg>
              <span className="text">Skills</span>
            </a>
          </li>
          <li>
            <a href="#education">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z"/></svg>
              <span className="text">Education</span>
            </a>
          </li>
          <li>
            <a href="#experience">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-640v560h560v-560h-80v280l-100-60-100 60v-280H200Zm0 560v-560 560Z"/></svg>
              <span className="text">Experience</span>
            </a>
          </li>
          <li>
            <a href="#contact">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M560-520h280v-200H560v200Zm140-50-100-70v-40l100 70 100-70v40l-100 70ZM80-120q-33 0-56.5-23.5T0-200v-560q0-33 23.5-56.5T80-840h800q33 0 56.5 23.5T960-760v560q0 33-23.5 56.5T880-120H80Zm556-80h244v-560H80v560h4q42-75 116-117.5T360-360q86 0 160 42.5T636-200ZM360-400q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM182-200h356q-34-38-80.5-59T360-280q-51 0-97 21t-81 59Zm178-280q-17 0-28.5-11.5T320-520q0-17 11.5-28.5T360-560q17 0 28.5 11.5T400-520q0 17-11.5 28.5T360-480Zm120 0Z"/></svg>
              <span className="text">Contact</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content">
        <div className="header">
          <h1 id="home">Software Engineer Portfolio</h1>
        </div>
        <div className="details">
          <img src={profile} alt="profile picture" className="face"/>
        </div>
        <div className="details" id="about">
          <h2>About</h2>
          <p className="introduction">
          Hello, I'm Darren Tan Thong En from Miri Sarawak Malaysia, a student taking Bachelor's of Software Engineeringt at Curtin University Malaysia. 
          I am passionate about exploring new technologies and applying them to solve real-world problems. 
          As I continue my journey in the field of computing, I'm eager to learn, grow, and embrace new challenges. 
          Through this portfolio, I hope to showcase my skills, capabilities, and the progress I've made in my learning and projects.
          </p>
          <br/>
          <p>
            As a dedicated Software Engineering student at Curtin University Malaysia, 
            I have developed a solid foundation in programming through both academic coursework and hands-on projects with technical skills in C++, C, Java, MySQL, Git, Github.
          </p>
          <br/>
          <p>
            During my semester break, I dedicated my time to learning new skills such as HTML, CSS, JavaScript, Dart, Flutter, and React.js. 
            I applied these skills to various personal projects, including creating my own website portfolio and developing mobile apps. 
            Additionally, my experience with Git and collaborative software development projects has equipped me to work effectively in team environments.
          </p>
          <br/>
          <p>
            Beyond my technical skills, I am passionate about designing user-friendly and visually appealing websites. 
            My role as a Website Helper with the IEEE Website Team has deepened my understanding of web development best practices and the importance of optimizing user experiences. 
            Currently, I am working on a project with my team to develop a STEM Playground app for an upcoming event. 
            This app will provide attendees with information on various showcase projects and allow them to vote for their favorite groups.
          </p>
        </div>
        <div className="details" id="skills">
          <h2>Technical Skills</h2>
          <ul>
            <li>C++ (Fluent)</li>
            <li>Java (Fluent)</li>
            <li>MySQL (Fluent)</li>
            <li>C(Fluent)</li>
            <li>Git (Fluent)</li>
            <li>GitHub (Fluent)</li>
            <li>HTML (Fluent)</li>
            <li>CSS (Good)</li>
            <li>JavaScript (Good)</li>
            <li>Dart (Good)</li>
            <li>Flutter (Good)</li>
            <li>Reactjs (Good)</li>
          </ul>
        </div>
        <div className="details" id="education">
          <h3>Education</h3>
          <p>
            <b>Curtin University Malaysia</b>
            <br />
            Bachelor of Science in Software Engineering
            <br />
            (2022 - 2023)
            <br /><br />
            Bachelor of Science in Software Engineering
            <br />
            (2023 - Present)
          </p>
        </div>
        <div className="details" id="CC">
          <h2>Co-Curricular/Volunteer Activities</h2>
          <p className="coc">
            1)  Google Developers Student Club (GDSC) - (Vice President).
            <br />
            2)  Curtin Strikers Bowling Club - (Secretary).
            <br />
            3)  Curtin Cup 2024 Committee - (Bowling Organizer).
            <br />
            4)  IEEE Curtin Malaysia Student Branch - (Website Helper).
          </p>
        </div>
        <div className="details" id="achievement">
          <h2>Achievements</h2>
          <p>
            1.  Computing Nation Programming Competition 2024 - 2nd Runner Up.
          </p>
        </div>
        <div className="details" id="experience">
          <h2>Programming Experience</h2>
          <table class="experience">
            <thead>
              <tr>
                <th class="tbheader">Projects</th>
                <th class="tbheader">Completion Period</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><a href="https://github.com/darren2581/Exhibition-Feedback-System">Exhibition Feedback System</a></td>
                <td>01/05/2023 - 10/06/2023</td>
              </tr>
              <tr>
                <td><a href="https://github.com/darren2581/Team-Statistics-System">Team Statistics System</a></td>
                <td>16/09/2023 - 15/10/2023</td>
              </tr>
              <tr>
                <td><a href="https://github.com/darren2581/FIFA-World-Cup-Database">FIFA World Cup Database</a></td>
                <td>29/09/2023 - 15/10/2024</td>
              </tr>
              <tr>
                <td><a href="https://github.com/darren2581/2D-Snake-Game">2D Snake Game</a></td>
                <td>22/03/2024 - 19/05/2024</td>
              </tr>
              <tr>
                <td><a href="https://github.com/darren2581/Numerology-Analysis-System">Numerology Analysis System</a></td>
                <td>25/04/2024 - 24/05/2024</td>
              </tr>
              <tr>
                <td><a href="https://github.com/darren2581/Airline-Management-System">Airline Management System</a></td>
                <td>01/05/2024 - 31/05/2024</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="details" id="contact">
          <h2>Contact Details</h2>
            <p><em>Email:</em> darrentanthongen@gmail.com</p>
            <p><em>Phone:</em> +6014-5752581</p>
            <p><em>Instagram:</em><a href="https://www.instagram.com/darren_2581"> darren_2581</a></p>
            <p><em>Github: </em><a href="https://github.com/darren2581"> www.github.com/darren2581</a></p>
            <p><em>LinkedIn:</em><a href="https://www.linkedin.com/in/darren2581"> www.linkedin.com/in/darren2581</a></p>
        </div>
        <div className="resume">
          <a href={resumeUrl} download="resume.pdf" id="resume">Download Resume</a>
        </div>
      </div>
    </div>
  );
}

export default App;
